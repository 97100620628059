<template>
    <div class='choose_number'>
        <ul class="tab">
            <li class="tab_item" 
            v-for="(tab, index) in tabs" 
            :key="index"
            :class="{'tab_active': index == currentIndex}"
            @click="changeTab(index)">
                {{tab.label}}
            </li>
        </ul>
        <OpenNumbers eventName="" :isChoose="true"  :showIcon="false" :balls="currentBalls" :showMoreIcon="false">
            <div @click.stop="getBetNuber" v-if="login" class="trans_icon">
                <span></span>
            </div>
        </OpenNumbers>
        <div class="choose_number_tip">
            <p>{{currentTip}}<span v-if="!login" class="re_choose_number" @click="getBetNuber">换一注</span></p>
            <p v-show="showButton" @click="genMessage">短信选号</p>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, reactive, toRefs } from 'vue'
import OpenNumbers from '../OpenNumber.vue'
import { geneBetNumbersIndex, arrSort, sendMessage, toast, getVisitor, canPour } from '../../utils/util'
import { chooseNumberArr } from '../../common-data'
import { openBonnusTime } from '../../utils/api'
export default {
    name: '',
    components: {OpenNumbers},
    props: {
        login: {
            type: Boolean,
            default: false
        },
        showButton: {
            type: Boolean,
            default: true
        },
        canLottery: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
         const state= reactive({
             tabs: [
                 {label: '双色球', issueInfo: '', gameId: 11, redBall: [1,2,3,4,5,6], blueBall: [], winningCode: '1,2,3,4,5,6#07', issueName: '2020075'},
                 {label: '福彩3D', issueInfo: '', gameId: 51, redBall: [1,2,3], numbers: '1,2,3', issueName: '2020075'},
                 {label: '七乐彩', issueInfo: '', gameId: 24, redBall: [1,2,3,4,5], numbers: '1,2,3,4,5,6#07', issueName: '2020075'}
             ],
             tips: [
                 '2元赢取1000万 每周二、四、日 21:30 开奖',
                 '每天20:30开奖',
                 '每周一、三、五21:30开奖'
             ],
             currentIndex: 0,
             mode: {}
        })
        const currentBalls = computed(() => {
            return state.tabs[state.currentIndex]
        })
        const currentTip = computed(() => {
            return state.tips[state.currentIndex]
        })
        getMode()
        // 点击切换
        function changeTab(index) {
            state.currentIndex = index
            getMode()
            getBetNuber()
        }
        // 获取投注
        function getMode(id = currentBalls.value.gameId) {
            state.mode = chooseNumberArr.get(id).modes[0]
        }
        // 完整数字
        function fillNumber(startNum, i) {
            return startNum == 0 ? i + '' :
                   i < 9 ? '0' + (i + 1) :
                   (i + 1) + ''
        }
        // 获取投注号码
        function getBetNuber() {
            const numArr = geneBetNumbersIndex(state.mode)
            const balls = state.mode.balls, startNum = state.mode.startNum
            let redBalls = [], blueBalls = []
            numArr.forEach((numLine, index) => {
                if (balls[index].color == 'red') {
                    numLine.forEach(num => {
                        redBalls.push(fillNumber(startNum, parseInt(num)))
                    })
                } else {
                    numLine.forEach(num => {
                        blueBalls.push(fillNumber(startNum, parseInt(num)))
                    })
                }
            })
            state.tabs[state.currentIndex].redBall = arrSort(redBalls)
            state.tabs[state.currentIndex].blueBall = arrSort(blueBalls)
        }
        onMounted(() => {

        })
        getBetNuber()
        // 生成投注短信
        function genMessage() {
            if (!props.canLottery) {
                toast('该功能暂不可用')
                return
            }
            if (!currentBalls.value.canPour) {
                toast('不在奖期，不能投注')
                return
            }
            if (!props.login) {
                toast('登录后才能投注')
                return
            } else if (getVisitor() == 'true') {
                toast('注册会员才能投注')
                return
            } else {
                let arr = ''
                currentBalls.value.redBall.forEach(ele => arr+=ele)
                currentBalls.value.blueBall && currentBalls.value.blueBall.forEach(ele => arr+=ele)
                const orderNumber = state.mode.orderNumber
                const flag = Array.isArray(orderNumber)
                sendMessage(`${flag ? orderNumber[0] : orderNumber }#${arr}`)
            }
        }
        // 获取最近开奖时间
        async function loadData() {
            try {
                let i = 3
                while(i) {
                    let ele = state.tabs[i-1]
                    let result = await openBonnusTime(ele.gameId)
                    if (result.code == 0 && result.data) {
                        const info = result.data
                        ele.issueName = info.issueName
                        ele.prizeDate = info.clientEndTime
                        ele.canPour = canPour(info.startTime, info.endTime)
                        // console.log(ele.canPour)
                    }
                    i--
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        return {
            ...toRefs(state),
            currentBalls,
            changeTab,
            currentTip,
            genMessage,
            getBetNuber
        }
    },
}
</script>
<style lang='scss' scoped>
.choose_number {
    background: #fff;
    border-radius: $box-radius-small;
    // border: solid 1px #fff;
    padding: 0;
    position: relative;
    .tab {
        display: flex;
        justify-content: space-between;
        // border-bottom: solid $line-gray 1px;
        background: #e5e5e5;
        margin-bottom: 10px;
        &_item {
            padding: 5px 10px;
            width: 33%;
            text-align: center;
        }
        &_active {
            background: #fff;
            color: $theme-dark;
            font-weight: 700;
            margin-bottom: -1px;
            border-radius: $box-radius-small  $box-radius-small 0 0;
        }
    }

    &_tip {
        padding: 0 $margin-big $margin-big $margin-big;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        p {
            &:first-child {
                font-size: $font-less;
                color: $gray-middle;
            }
            &:last-child {
                color: #fff;
                background: $theme-dark;
                font-size: $font-small;
                border-radius: $box-radius-small;
                padding: 6px 0px;
                width: 70px;
                text-align: center;
            }
        }
        .re_choose_number {
            display: inline-block;
            background: url('../../assets/imgs/home_refresh.png') right center no-repeat;
            background-size: contain;
            padding-right: 20px;
        }
    }
    .trans_icon {
        position: relative;
        span {
            position: absolute;
            top: 8px;
            left: 10px;
            width: 18px;
            height: 18px;
            background: url('../../assets/imgs/home_refresh.png') right center no-repeat;
            background-size: contain;
        }
    }
}
</style>