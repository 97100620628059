<template>
    <ul class='choose_lottery'>
        <li class="choose_lottery_item bottom_gray" @click="goLottery(item.id)" v-for="(item) in lotterys" :key="item.id">
            <div class="item_left" :style="bgStyle(item.url)">
                <div>
                    <p>{{item.name}}</p>
                    <small>{{item.tip}}</small>
                </div>
            </div>
            <div class="item_right" v-if="showButton">
            </div>
        </li>
    </ul>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { lotterys } from '../../common-data'
export default {
    name: '',
    props: {
        showButton: {
            type: Boolean,
            default: false
        },
        canLottery: {
            type: Boolean,
            default: true
        },
        tipText: {
            type: String,
            default: '登录后才能短信选号'
        }
    },
    setup(props, context) {
        console.log(props)
        const state= reactive({
        })
        function bgStyle(url) {
            return {
                backgroundImage: `url(${url})`,
            }
        }
        function goLottery(gameId) {
            context.emit('lotteryEvent', gameId)
        }
        return {
            ...toRefs(state),
            lotterys,
            bgStyle,
            goLottery
        }
    },
}
</script>
<style lang='scss' scoped>
.choose_lottery {
    &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 3px 10px 0;
        margin: 0 7px;
        width: 163px;
        // border-bottom: $line-gray 1px solid;
        &:last-child {
            border-bottom: none;
        }
        font-size: $font-middle;
        &:first-child {
            .item {
                &_left {
                    small {
                        color: $theme-light;
                        background: rgba($color: $theme-light, $alpha: 0.1);
                        padding: 0 0px;
                        border-radius: $box-radius-small;
                    }
                }
            }
        }
        .item {
            &_left {
                display: flex;
                align-items: center;
                height: 40px;
                background-size: 40px;
                padding-left: 43px;
                background-repeat: no-repeat;
                background-position: center left;
                small {
                    color: $gray-light;
                    font-size: $font-less;
                    display: inline-block;
                    transform: scale(0.8);
                    margin-left: -8px;
                }
            }
            &_right {
                background: url('../../assets/imgs/pink_arrow.png') center center no-repeat;
                background-size: contain;
                color: #fff;
                border-radius: $box-radius-small;
                width: 13px;
                height: 13px;
            }
        }
    }
}
</style>