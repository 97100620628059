<template>
    <ul class='contact'>
        <li class="contact_item" v-for="(item, index) in contactInfo" :key="index" @click="go(item.route)">
            <img :src="item.img" alt="">
        </li>
    </ul>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { jumpTo } from '../../utils/util'
export default {
    name: '',
    setup() {
         const state= reactive({
            contactInfo: [
                {img: require('../../assets/imgs/home_yd_contact.png'), label: '移动', route: '/message-order'},
                {img: require('../../assets/imgs/home_lt_contact.png'), label: '联通', route: '/message-order'}
            ], 
        })
        function go(route) {
                jumpTo(route)
        }
        return {
            ...toRefs(state),
            go
        }
    },
}
</script>
<style lang='scss' scoped>
.contact {
    padding: 5px;
    &_item {
        margin-top: 4px;
        img {
            width: 100%;
            height: 114px;
        }
    }
}
</style>